import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { BasicModal } from "../../../Shared";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { addDays, format } from "date-fns";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { GroupLoan } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../utils";
import { GroupLoanForm } from "../GroupLoanForm";
import { GroupLoanPdf } from "./GroupLoanPdf";
const GroupLoanController = new GroupLoan();

export function GroupLoanItem(props) {
  const { groupLoan, onReload } = props;
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const openUpdateGroup = () => {
    setTitleModal(`Actualizar ${groupLoan.name}`);
    onOpenCloseModal();
  };
  console.log(groupLoan);

  const onDelete = () => {
    Swal.fire({
      title: "Eliminar el grupo",
      text: `¿Estás seguro de que deseas eliminar el grupo  ${groupLoan.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onDeleteConfirm();
      }
    });
  };
  const onDeleteConfirm = async () => {
    try {
      await GroupLoanController.deleteGroupLoan(accessToken, groupLoan._id);
      toast.success("Grupo eliminado correctamente");
      onReload();
    } catch (error) {
      toast.error(error.msg);
    }
  };

  // Calcular el monto total solicitado

  const totalAmountRequested = groupLoan.loanRequests.reduce(
    (total, loan) => total + loan.amountRequested,
    0
  );
  // Calcular el monto total solicitado con el interes
  const TotalAmount = groupLoan.loanRequests.reduce(
    (total, loan) => total + loan.totalAmount,
    0
  );

  // Calcular la cantidad de pagos pendientes y pagados
  let totalPendingPayments = 0;
  let totalPaidPayments = 0;

  groupLoan.loanRequests.forEach((loanRequest) => {
    // Obtener los pagos de esta solicitud de préstamo
    const payments = loanRequest.payments;

    // Calcular la cantidad de pagos pendientes y pagados para esta solicitud de préstamo
    const pendingPayments = payments.filter((payment) => !payment.paid);
    const paidPayments = payments.filter((payment) => payment.paid);

    // Sumarlos a los totales globales
    totalPendingPayments += pendingPayments.length;
    totalPaidPayments += paidPayments.length;
  });

  return (
    <>
      {/* Renderizar una fila por cada solicitud de préstamo */}

      <tr className="border-b bg-cyan-800 border-white">
        <td className="text-white px-6 py-4 font-medium text-center">
          {" "}
          {groupLoan.name}
        </td>
        <td className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {groupLoan.loanRequests.length}
        </td>
        <td className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {groupLoan.admin.firstname} {groupLoan.admin.lastname}
        </td>
        <td className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {formatCurrency(totalAmountRequested)}
        </td>
        <td className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {formatCurrency(TotalAmount)}
        </td>
        <td className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {totalPendingPayments}
        </td>
        <td className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {totalPaidPayments}
        </td>
        <td className="border border-slate-50 py-3 px-6 text-center">
          <div className="flex item-center justify-center">
            <Button icon primary onClick={openUpdateGroup}>
              <Icon name="pencil" />
            </Button>
            <Button
              icon
              onClick={() => navigate(`/admin/grouploan/${groupLoan._id}`)}
            >
              <Icon name="eye" />
            </Button>
            <Button icon color="red" onClick={onDelete}>
              <Icon name="trash" />
            </Button>
            <PDFDownloadLink
              document={<GroupLoanPdf groupLoan={groupLoan} />}
              fileName={`Corrida${groupLoan.name}.pdf`}
            >
              <Button icon>
                <Icon name="file pdf" />
              </Button>
            </PDFDownloadLink>
          </div>
        </td>
      </tr>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <GroupLoanForm
          close={onOpenCloseModal}
          onReload={onReload}
          groupLoan={groupLoan}
        />
      </BasicModal>
    </>
  );
}
