import { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { GroupLoan, LoanRequest } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { toast } from "react-toastify";
import { initialValues, validationSchema } from "./GroupLoanForm.form";

const GroupLoanController = new GroupLoan();
const LoanRequestController = new LoanRequest();

export function GroupLoanForm(props) {
  const [loanRequestOptions, setLoanRequestOptions] = useState([]);
  const [loadingLoanRequests, setLoadingLoanRequests] = useState(true); // Estado para controlar la carga de roles
  const { close, onReload, groupLoan } = props;
  const { accessToken } = useAuth();
  const isPaid = false;

  const formik = useFormik({
    initialValues: initialValues(groupLoan),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!groupLoan) {
          await GroupLoanController.createGroupLoan(accessToken, formValue);
          toast.success("Grupo creado correctamente.");
        } else {
          await GroupLoanController.updateGroupLoan(
            accessToken,
            groupLoan._id,
            formValue
          );
          toast.success("Grupo actualizado correctamente.");
        }
        onReload();
        close();
      } catch (error) {
        toast.error(error.msg);
      }
    },
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const loanRequests = await LoanRequestController.getLoanRequests(
          accessToken,
          isPaid
        );

        // Filtrar los préstamos grupales (donde isGroup es true)
        const groupLoanRequests = loanRequests.filter(
          (loanRequest) => loanRequest.isGroupLoan == true
        );

        // Filtrar los préstamos grupales que no estén en ningún grupo
        const availableGroupLoanRequests = groupLoanRequests.filter(
          (loanRequest) => loanRequest.isGroupLoan == true
        );

        if (availableGroupLoanRequests.length === 0) {
          // No hay préstamos grupales disponibles
          setLoanRequestOptions([]);
          setLoadingLoanRequests(false);
          toast.info("No hay préstamos grupales disponibles.");
        } else {
          setLoanRequestOptions(
            availableGroupLoanRequests.map((loanRequest) => ({
              key: loanRequest._id,
              text: loanRequest.code,
              value: loanRequest._id,
            }))
          );
          setLoadingLoanRequests(false);
        }
      } catch (error) {
        toast.error(error.msg);
      }
    };

    fetchRoles();
  }, [accessToken, isPaid]);

  return (
    <Form className="" onSubmit={formik.handleSubmit}>
      <Form.Group widths={"equal"}>
        <Form.Dropdown
          label="Prestamos grupales"
          name="loanRequestIds"
          placeholder="Selecione un Prestamo"
          options={loanRequestOptions}
          value={formik.values.loanRequestIds}
          onChange={(e, { value }) =>
            formik.setFieldValue("loanRequestIds", value)
          }
          onBlur={formik.handleBlur}
          error={formik.errors.loanRequestIds}
          selection
          multiple
        />
        <Form.Input
          name="name"
          label="Nombre del grupo"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.errors.name}
        />
      </Form.Group>

      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {groupLoan ? "Actualizar grupo" : "Crear grupo"}
      </Form.Button>
    </Form>
  );
}
