import React, { useEffect, useState } from "react";
import { DashboardCount, DashboardDoughnut } from "../../../components/Admin";
import { User } from "../../../api";
import { useAuth } from "../../../hooks";
import { Loader } from "semantic-ui-react";

const UserController = new User();

export function Dashboard() {
  const [message, setMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [loadingUser, setLoadingUser] = useState(true);
  const { accessToken } = useAuth();

  useEffect(() => {
    const getGreeting = () => {
      const date = new Date();
      const hour = date.getHours();

      if (hour >= 6 && hour < 12) {
        return "Buenos días";
      } else if (hour >= 12 && hour < 20) {
        return "Buenas tardes";
      } else {
        return "Buenas noches";
      }
    };

    setMessage(getGreeting());
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await UserController.getMe(accessToken);
        const { username } = response;
        setUserName(username);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false); // Indicate that user data loading is complete
      }
    };

    fetchUserData();
  }, [accessToken]);

  return (
    <>
      {loadingUser ? (
        <Loader content="Cargando dashboard..." />
      ) : (
        <>
          <p className="text-3xl font-semibold text-left mb-4">
            {message}, {userName}
          </p>
          <div className="mb-4">
            <DashboardCount />
          </div>
          <div className="mb-4">
            <DashboardDoughnut />
          </div>
        </>
      )}
    </>
  );
}
