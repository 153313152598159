import Imghome from "../../../assets/jpg/home-banner.jpg";
import "./Banner.scss";

export function Banner() {
  return (
    <>
      <div className="relative pt-16 pb-32 flex content-center items-center justify-center ml-auto mr-auto">
        <div
          className="absolute w-[100%] h-[100%] bg-center bg-cover right-0 mt-56"
          style={{
            backgroundImage: `url(${Imghome})`,
          }}
        >
          {/* Fondo oscuro semi-transparente */}
          <div className="w-full h-full absolute bg-black opacity-60"></div>
        </div>
        <div className="container header relative pt-16 items-center flex h-screen max-h-860-px animate-fade-down animate-once animate-duration-[1800ms] animate-delay-1000 animate-fill-forwards">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
              <div className="pr-12">
                <h1 className="font-semibold text-7xl text-white">
                  <span className="text-[#FEB449]">
                    Kon<span className="text-[#FB5E33]">fiamos</span>
                  </span>
                </h1>
                <p className="mt-4 text-white mr-[78px] text-2xl text-justify">
                  Préstamos grupales y personales. Colabora o elige la
                  flexibilidad. Solicitud sencilla. Confianza en tu éxito
                  financiero.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
