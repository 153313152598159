import { ReactComponent as LogoWhite } from "./svg/LogoWhite.svg";
import { ReactComponent as logoLight } from "./images/logo-light.svg";
import { ReactComponent as logoDark } from "./images/logo-dark.svg";
import { ReactComponent as Logo } from "./svg/Logo.svg";

import authBg from "./jpg/auth-bg.jpg";
import homeBanner from "./jpg/home-banner.jpg";
import noAvatar from "./jpg/no-avatar.jpg";
import hero from "./images/hero.png";
const Icon = {
  Logo,
  LogoWhite,
  logoLight,
  logoDark,
};

const image = {
  authBg,
  homeBanner,
  noAvatar,
  hero,
};

export { Icon, image };
