import React, { useEffect, useState } from "react";
import { Menu, Icon, Dimmer, Loader } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { menu } from "../../../../api";
import { useAuth } from "../../../../hooks";
import "./AdminMenu.scss";

const menuController = new menu();

export function AdminMenu() {
  const { pathname } = useLocation();
  const {
    user: { role },
    accessToken,
  } = useAuth();
  const active = true;
  const isCurrentPath = (path) => path === pathname;
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMenuItems() {
      try {
        const response = await menuController.getMenus(accessToken, active);
        setMenuItems(response);
        setIsLoading(false); // Indicar que la carga ha finalizado
      } catch (error) {
        console.error("Error al obtener los menús", error);
        setIsLoading(false); // Indicar que la carga ha finalizado (incluso en caso de error)
      }
    }

    fetchMenuItems();
  }, [accessToken, active]);

  const userRoleNames = [role.name];

  return (
    <div>
      {isLoading ? ( // Mostrar spinner mientras se cargan los menús
        <Dimmer active>
          <Loader content="Cargando Menús..." />
        </Dimmer>
      ) : (
        <Menu fluid vertical icon text className="admin-menu">
          {menuItems.map((menuItem) => {
            const hasAccess = menuItem.roles.some((menuRole) =>
              userRoleNames.includes(menuRole.name)
            );
            return hasAccess ? (
              <Menu.Item
                key={menuItem._id}
                as={Link}
                to={menuItem.path}
                active={isCurrentPath(menuItem.path)}
              >
                <Icon name={menuItem.icon} />
                {menuItem.title}
              </Menu.Item>
            ) : null;
          })}
        </Menu>
      )}
    </div>
  );
}
