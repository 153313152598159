import { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { size, map } from "lodash";
import { menu } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { MenuItem } from "../MenuItem";
import { EmptyList } from "../../../Shared";
import ReactPaginate from "react-paginate";
import "./ListMenus.scss";

const MenuController = new menu();

export function ListMenus(props) {
  const { active, reload, onReload } = props;
  const [menus, setMenus] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Número de menus por página
  const { accessToken } = useAuth();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await MenuController.getMenus(accessToken, active);
        setMenus(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCustomers();
  }, [active, reload, accessToken]);

  if (!menus) return <Loader active inline="centered" />;
  if (size(menus) === 0) {
    return (
      <EmptyList
        title={
          menus === true ? "No hay menus activos." : "No hay menus inactivos."
        }
      />
    );
  }
  // Obtener la lista de clientes para la página actual
  const offset = currentPage * pageSize;
  const currentMenus = menus.slice(offset, offset + pageSize);

  // Calcular el número total de páginas
  const pageCount = Math.ceil(menus.length / pageSize);

  // Cambiar de página
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Cambiar la cantidad de clientes por página
  const handlePageSizeChange = (event) => {
    const size = parseInt(event.target.value);
    setPageSize(size);
    setCurrentPage(0);
  };

  return (
    <>
      <div className="pageSize-container">
        <label htmlFor="pageSize">Mostrar:</label>
        <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-base text-white">
          <thead className="bg-[#687584]">
            <tr className="border border-slate-50">
              <th className="py-3 px-6 text-center">Roles con acceso</th>
              <th className="py-3 px-6 text-center">Titulo del menu</th>
              <th className="py-3 px-6 text-center">Ruta del menu</th>
              <th className="py-3 px-6 text-center">Nombre de icono</th>
              <th className="py-3 px-6 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {map(currentMenus, (menu) => (
              <MenuItem key={menu._id} menu={menu} onReload={onReload} />
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}
