import { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { size, map } from "lodash";
import { GroupLoan } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { GroupLoanItem } from "../GroupLoanItem";
import { EmptyList } from "../../../Shared";
import ReactPaginate from "react-paginate";
import "./ListGroupLoans.scss";

const GroupLoanController = new GroupLoan();

export function ListGroupLoans(props) {
  const { reload, onReload } = props;
  const [groupLoans, setGroupLoans] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Número de préstamos grupales por página
  const { accessToken } = useAuth();

  useEffect(() => {
    const fetchGroupLoans = async () => {
      try {
        let validAccessToken = accessToken;
        const response = await GroupLoanController.getGroupLoans(
          validAccessToken
        );
        setGroupLoans(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGroupLoans();
  }, [reload]);

  // Obtener la lista de préstamos grupales para la página actual
  const offset = currentPage * pageSize;
  const currentGroupLoans = groupLoans
    ? groupLoans.slice(offset, offset + pageSize)
    : [];

  // Calcular el número total de páginas
  const pageCount = groupLoans ? Math.ceil(groupLoans.length / pageSize) : 0;

  // Cambiar de página
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Cambiar la cantidad de préstamos grupales por página
  const handlePageSizeChange = (event) => {
    const size = parseInt(event.target.value);
    setPageSize(size);
    setCurrentPage(0);
  };

  if (!groupLoans) return <Loader active inline="centered" />;
  if (size(groupLoans) === 0) {
    return <EmptyList title="No hay Préstamos grupales registstrados." />;
  }

  return (
    <>
      <div className="pageSize-container">
        <label htmlFor="pageSize">Mostrar:</label>
        <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
      </div>
      <div className="relative overflow-x-auto shadow-2xl sm:rounded-lg">
        <table className="w-full border border-slate-50 text-base text-center text-white">
          <thead className="border-b text-base uppercase bg-[#687584] text-white">
            <tr className="border border-slate-50">
              <th className="py-3 px-6 text-center sm:w-1/4">
                Código de grupo
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">
                Prestamos en el grupo
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">
                Admistrador del grupo
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">
                Monto solicitado
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">MONTO A PAGAR</th>
              <th className="py-3 px-6 text-center sm:w-1/4">
                Pagos pendientes
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">Pagos Pagados</th>
              <th className="py-3 px-6 text-center sm:w-1/4">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {map(currentGroupLoans, (groupLoan) => (
              <GroupLoanItem
                key={groupLoan._id}
                groupLoan={groupLoan}
                onReload={onReload}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}
