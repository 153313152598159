import { ENV } from "../utils";

export class GroupLoan {
  baseApi = ENV.BASE_API;

  async createGroupLoan(accessToken, data) {
    const url = `${this.baseApi}/${ENV.API_ROUTES.GROUPLOAN}`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status !== 201) throw result;

    return result;
  }
  catch(error) {
    throw error;
  }

  async updateGroupLoan(accessToken, idGroupLoan, GroupLoanData) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GROUPLOAN}/${idGroupLoan}`;
      const params = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(GroupLoanData),
      };
      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getGroupLoans(accessToken) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GROUPLOANS}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getGroupLoan(accessToken, idGroupLoan) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GROUPLOAN}/${idGroupLoan}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteGroupLoan(accessToken, idGroupLoan) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GROUPLOAN}/${idGroupLoan}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
}
