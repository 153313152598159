const SERVER_IP_DEV = "api.konfiamos.com";

export const ENV = {
  BASE_PATH: `https://${SERVER_IP_DEV}`,
  BASE_API: `https://${SERVER_IP_DEV}/api/v1`,
  API_ROUTES: {
    REGISTER: "auth/register",
    LOGIN: "auth/login",
    REFRESH_ACCESS_TOKEN: "auth/refresh_access_token",
    USER_ME: "user/me",
    ROLE: "role",
    ROLES: "roles",
    MENU: "menu",
    MENUS: "menus",
    USER: "user",
    USERS: "users",
    CUSTOMER: "customer",
    CUSTOMERS: "customers",
    LOANREQUEST: "loanrequest", //PRESTAMOS
    LOANREQUESTS: "loanrequests", //PRESTAMOS
    LOANPAYMENT: "loanpayment", // pagos de los prestamos
    PAYMENT: "payment", // Creacion de pagos parciales
    LOANPAYMENTS: "loanpayments", //ver los prestamos
    TOTALINVESTMENT: "totalinvestment", //ver lo invertido
    GROUPLOAN: "grouploan", // creacion de grupos de prestamos
    GROUPLOANS: "grouploans", // grupos de prestamos
    COMMENT: "comment", //Comentarios dentros de pago
  },
  JWT: {
    ACCESS: "access",
    REFRESH: "refresh",
  },
};
