import { addDays, format } from "date-fns";
import { RiWallet3Line } from "react-icons/ri";
import { Button, Loader, Grid } from "semantic-ui-react";

export function CustomerView(props) {
  const { customer, close } = props;

  if (!customer && customer !== null) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <RiWallet3Line size={48} color="#fff" className="mb-4" />
        <span className="text-white">El cliente no existe.</span>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-center">Cargando información del cliente...</div>
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full p-4 rounded-lg bg-cyan-800 border-white mb-4">
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <table className="w-full text-white">
                <tbody>
                  <tr>
                    <th>Nombre completo:</th>
                    <td>{`${customer.firstname} ${customer.lastname}`}</td>
                  </tr>
                  <tr>
                    <th>Género:</th>
                    <td>{customer.gender}</td>
                  </tr>
                  <tr>
                    <th>Correo Electrónico:</th>
                    <td>{customer.email}</td>
                  </tr>
                  <tr>
                    <th>Teléfono:</th>
                    <td>{customer.phone}</td>
                  </tr>
                  <tr>
                    <th>Teléfono Celular:</th>
                    <td>{customer.cellnumber}</td>
                  </tr>
                  <tr>
                    <th>Ocupación:</th>
                    <td>{customer.ocupation}</td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
            <Grid.Column>
              <table className="w-full text-white">
                <tbody>
                  <tr>
                    <th>Domicilio:</th>
                    <td>
                      {`${customer.street} ${customer.number_ext_address} ${customer.number_int_address}`}
                    </td>
                  </tr>
                  <tr>
                    <th>Colonia y municipio:</th>
                    <td>{`${customer.neighborhood}, ${customer.municipality}`}</td>
                  </tr>
                  <tr>
                    <th>Código postal:</th>
                    <td>{customer.zip}</td>
                  </tr>
                  <tr>
                    <th>Estado y ciudad:</th>
                    <td>{`${customer.state}, ${customer.city}`}</td>
                  </tr>
                  <tr>
                    <th>Fecha de creación:</th>
                    <td>
                      {format(
                        new Date(customer.createdAt),
                        "dd/MM/yyyy hh:mm:ss a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Última actualización:</th>
                    <td>
                      {format(
                        new Date(customer.updatedAt),
                        "dd/MM/yyyy hh:mm:ss a"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Botón para descargar 'accountStatus' */}
        <div className="text-center mt-4">
          {customer.accountStatus ? (
            <Button
              as="a"
              href={customer.accountStatus}
              target="_blank"
              rel="noopener noreferrer"
              color="blue"
              icon="download"
              content="Descargar estado de cuenta"
            />
          ) : (
            <Button
              disabled
              color="grey"
              content="No hay documento de estado de cuenta"
            />
          )}
        </div>

        {/* Botón para descargar 'identification' */}
        <div className="text-center mt-4">
          {customer.identification ? (
            <Button
              as="a"
              href={customer.identification}
              target="_blank"
              rel="noopener noreferrer"
              color="blue"
              icon="download"
              content="Descargar Identificación"
            />
          ) : (
            <Button
              disabled
              color="grey"
              content="No hay documento de Identificación"
            />
          )}
        </div>
      </div>

      <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
        <div className=" w-full p-4 rounded-lg  bg-cyan-800 border-white">
          <div className="flex flex-row items-center justify-between mb-6">
            <div className="flex flex-col">
              <div className="text-lg font-bold text-white">Cliente</div>
              <div className="text-base font-bold text-white">
                <span>Información Préstamos</span>
              </div>
            </div>
            <div className="relative inline-block text-left z-10"></div>
          </div>
          <div className="p-0 w-full">
            <table className="w-full">
              <thead>
                <tr className="border-b bg-cyan-800 border-white">
                  <th className="p-3 font-bold text-white">Código</th>
                  <th className="p-3 font-bold text-white">Monto solicitado</th>
                  <th className="p-3 font-bold text-white">Monto a pagar</th>
                  <th className="p-3 font-bold text-white">Estatus</th>
                  <th className="p-3 font-bold text-white">Fecha de inicio</th>
                  <th className="p-3 font-bold text-white">Fecha de Fin</th>
                  <th className="p-3 font-bold text-white">Período</th>
                  <th className="p-3 font-bold text-white">Monto de interés</th>
                  <th className="p-3 font-bold text-white">Tasa de interés</th>
                  <th className="p-3 font-bold text-white">Período pagado</th>
                  <th className="p-3 font-bold text-white">Total pagado</th>
                </tr>
              </thead>
              <tbody>
                {customer.loanRequests && customer.loanRequests.length > 0 ? (
                  customer.loanRequests.map((loan) => (
                    <tr key={loan._id} className="border-b border-slate-50">
                      <td className="p-3 text-white">{loan.code}</td>
                      <td className="p-3 text-white">{loan.amountRequested}</td>
                      <td className="p-3 text-white">{loan.totalAmount}</td>
                      <td className="p-3 text-white">{loan.status}</td>
                      <td className="p-3 text-white">
                        {loan.startDate
                          ? format(
                              addDays(new Date(loan.startDate), 1),
                              "dd/MM/yyyy "
                            )
                          : "No hay datos"}
                      </td>
                      <td className="p-3 text-white">
                        {loan.endDate
                          ? format(
                              addDays(new Date(loan.endDate), 1),
                              "dd/MM/yyyy "
                            )
                          : "No hay datos"}
                      </td>
                      <td className="p-3 text-white">{loan.period}</td>
                      <td className="p-3 text-white">{loan.interestAmount}</td>
                      <td className="p-3 text-white">{loan.interestRate}</td>
                      <td className="p-3 text-white">{loan.periodPaid}</td>
                      <td className="p-3 text-white">{loan.totalPaid}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-3" colSpan="11">
                      <div className="flex flex-col items-center justify-center py-8">
                        <RiWallet3Line
                          size={48}
                          color="#fff"
                          className="mb-4"
                        />

                        <span className="text-white">
                          El cliente no tiene nungún prestamo.
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Botón de Cerrar */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        {/* Botón de Cerrar en el lado derecho */}
        <Button
          content="Cerrar"
          icon="close"
          labelPosition="left"
          onClick={close}
        />
      </div>
    </>
  );
}
