import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../assets";
import { useAuth } from "../../../hooks/useAuth";

// Función para generar un elemento de menú
function MenuItem({ to, text, active, onClick }) {
  const activeClass = active
    ? "text-black-700 hover:text-blue-600"
    : "text-black-700 hover:text-blue-500";
  return (
    <li className="flex items-center hover:scale-125 duration-300 py-1">
      <Link
        to={to}
        className={`px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold ${activeClass}`}
        onClick={onClick}
      >
        {text}
      </Link>
    </li>
  );
}

export function TopBar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <nav className="fixed w-full left-0 top-0   z-50  flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg bg-white shadow">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            to="/"
            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          >
            <Icon.Logo className="w-20 h-20" alt="" />
          </Link>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
            (navbarOpen ? " block" : " hidden")
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {/* <MenuItem
              to="/nosotros"
              text="Nosotros"
              active={window.location.href.indexOf("/nosotros") !== -1}
            />
            <MenuItem
              to="/ubicacion"
              text="Ubicación"
              active={window.location.href.indexOf("/ubicacion") !== -1}
            />
            <MenuItem
              to="/servicios"
              text="Servicios"
              active={window.location.href.indexOf("/servicios") !== -1}
            /> */}
            <MenuItem
              to="/admin"
              text="Iniciar sesión"
              active={window.location.href.indexOf("/admin") !== -1}
            />
          </ul>
        </div>
      </div>
    </nav>
  );
}
