import { useState, useEffect } from "react";
import { Button, Icon, Modal, Input, Table, Loader } from "semantic-ui-react";
import { addDays, format } from "date-fns";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { LoanRequest } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { formatCurrency } from "../../../../utils";

const LoanRequestController = new LoanRequest();

export function LoanPaymentsItem(props) {
  const { loanPayment, onReload } = props;
  const { accessToken } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState("");
  const [partialPaymentsHistory, setPartialPaymentsHistory] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editPartialPayment, setEditPartialPayment] = useState(null);
  const [editedPartialPaymentAmount, setEditedPartialPaymentAmount] =
    useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [partialPaymentTotal, setPartialPaymentTotal] = useState(0);
  const [originalPartialPaymentAmount, setOriginalPartialPaymentAmount] =
    useState(0);

  // Calcular los valores iniciales de remainingAmount y partialPaymentTotal
  useEffect(() => {
    const totalAmount = loanPayment.paymentAmount;
    const totalPartialPaymentsAmount = loanPayment.partialPayments.reduce(
      (total, partialPayment) => total + partialPayment.amount,
      0
    );
    setPartialPaymentTotal(totalPartialPaymentsAmount);
    setRemainingAmount(totalAmount - totalPartialPaymentsAmount);
  }, [loanPayment]);

  if (loanPayment === null) {
    // Muestra el loader mientras los datos se están cargando
    return <Loader active inline="centered" />;
  }

  const openDesactivateActivateConfirm = () => {
    const action = loanPayment.paid ? "Revertir" : "Confirmar";
    Swal.fire({
      title: `${action} el pago del préstamo`,
      text: `¿Estás seguro de que deseas ${action.toLowerCase()} el pago del préstamo ${
        loanPayment.loanRequest.code
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Sí, ${action.toLowerCase()}`,
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onActivateDesactivate();
      }
    });
  };

  const onActivateDesactivate = async () => {
    try {
      const paymentStatusUpdate = { paid: !loanPayment.paid };
      if (loanPayment.paid) {
        paymentStatusUpdate.partialPayments = [];
      }
      await LoanRequestController.updatePaymentStatus(
        accessToken,
        loanPayment._id,
        paymentStatusUpdate
      );
      onReload();
      const action = loanPayment.paid ? "revertido" : "confirmado";
      toast.success(`Estado de pago ${action} correctamente`);
    } catch (error) {
      toast.error("Error al actualizar el estado de pago.");
    }
  };

  const registerPartialPayment = async () => {
    try {
      const paymentData = {
        paymentId: loanPayment._id,
        partialPayment: true,
        partialPaymentAmount: parseFloat(partialPaymentAmount),
      };

      await LoanRequestController.createPartialPayment(
        accessToken,
        paymentData
      );

      setModalOpen(false);
      setPartialPaymentAmount("");
      onReload();

      setPartialPaymentsHistory([
        ...partialPaymentsHistory,
        {
          amount: parseFloat(partialPaymentAmount),
          date: new Date(),
        },
      ]);

      toast.success("Pago parcial registrado correctamente.");

      // Recalcular partialPaymentTotal después de agregar el pago parcial
      const newPartialPaymentTotal =
        partialPaymentTotal + parseFloat(partialPaymentAmount);
      setPartialPaymentTotal(newPartialPaymentTotal);
      setRemainingAmount(loanPayment.paymentAmount - newPartialPaymentTotal);
    } catch (error) {
      toast.error("Error al registrar el pago parcial.");
      console.log(error);
    }
  };

  const openEditModal = (partialPayment) => {
    if (partialPayment && partialPayment.paymentDate) {
      // Guardamos la cantidad original antes de abrir el modal de edición
      setOriginalPartialPaymentAmount(partialPayment.amount);
      setEditPartialPayment(partialPayment);
      setEditedPartialPaymentAmount(partialPayment.amount.toString());
      setEditModalOpen(true);
    } else {
      // Manejo de caso donde partialPayment es undefined o no tiene la propiedad 'paymentDate'
      console.error("partialPayment no es válido o no tiene paymentDate");
    }
  };

  const deletePartialPayment = async (partialPaymentId) => {
    try {
      await LoanRequestController.deletePartialPayment(
        accessToken,
        loanPayment._id,
        partialPaymentId
      );

      const updatedPartialPayments = partialPaymentsHistory.filter(
        (partialPayment) => partialPayment._id !== partialPaymentId
      );
      setPartialPaymentsHistory(updatedPartialPayments);
      onReload();

      toast.success("Pago parcial eliminado correctamente.");

      // Recalcular partialPaymentTotal después de eliminar el pago parcial
      const deletedPartialPayment = partialPaymentsHistory.find(
        (p) => p._id === partialPaymentId
      );
      const newPartialPaymentTotal =
        partialPaymentTotal -
        (deletedPartialPayment ? deletedPartialPayment.amount : 0);
      setPartialPaymentTotal(newPartialPaymentTotal);
      setRemainingAmount(loanPayment.paymentAmount - newPartialPaymentTotal);
    } catch (error) {
      toast.error("Error al eliminar el pago parcial.");
      console.log(error);
    }
  };

  const saveEditedPartialPayment = async () => {
    try {
      const updatedPartialPayment = {
        ...editPartialPayment,
        amount: parseFloat(editedPartialPaymentAmount),
      };

      await LoanRequestController.updatePartialPayment(
        accessToken,
        loanPayment._id,
        editPartialPayment._id,
        { amount: parseFloat(editedPartialPaymentAmount) }
      );

      const updatedPartialPayments = partialPaymentsHistory.map(
        (partialPayment) =>
          partialPayment._id === updatedPartialPayment._id
            ? { ...partialPayment, amount: updatedPartialPayment.amount }
            : partialPayment
      );
      setPartialPaymentsHistory(updatedPartialPayments);

      setEditModalOpen(false);
      onReload();

      toast.success("Pago parcial editado correctamente.");

      // Recalcular partialPaymentTotal después de editar el pago parcial
      const newPartialPaymentTotal =
        partialPaymentTotal -
        originalPartialPaymentAmount +
        parseFloat(editedPartialPaymentAmount);
      setPartialPaymentTotal(newPartialPaymentTotal);
      setRemainingAmount(loanPayment.paymentAmount - newPartialPaymentTotal);
    } catch (error) {
      toast.error("Error al editar el pago parcial.");
    }
  };

  return (
    <>
      <Table.Row className="border-b bg-cyan-800 border-white">
        <Table.Cell className="text-white px-6 py-4 font-medium text-center">
          {loanPayment.paymentDate
            ? format(
                addDays(new Date(loanPayment.paymentDate), 1),
                "dd/MM/yyyy "
              )
            : "No hay datos"}
        </Table.Cell>
        <Table.Cell className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {loanPayment.dueDate
            ? format(addDays(new Date(loanPayment.dueDate), 1), "dd/MM/yyyy ")
            : "No hay datos"}
        </Table.Cell>
        <Table.Cell className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {loanPayment.paymentAmount
            ? formatCurrency(loanPayment.paymentAmount)
            : "No hay datos"}
        </Table.Cell>
        <Table.Cell className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {loanPayment.paid ? "Pagado" : "No pagado"}
        </Table.Cell>
        {/* <Table.Cell className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {loanPayment.partialPayment ? "Sí" : "No"}
        </Table.Cell> */}
        <Table.Cell className="border border-slate-50 text-white font-medium px-6 py-4 text-center">
          {loanPayment.partialPaymentTotal
            ? formatCurrency(loanPayment.partialPaymentTotal)
            : "0"}
        </Table.Cell>

        <Table.Cell className="border border-slate-50 py-3 px-6 text-center">
          <div className="flex items-center justify-center">
            <Button
              icon
              color={loanPayment.paid ? "red" : "teal"}
              onClick={openDesactivateActivateConfirm}
            >
              <Icon name={loanPayment.paid ? "undo" : "check"} />
            </Button>
            <Button
              icon
              color="blue"
              onClick={() => setModalOpen(true)}
              disabled={loanPayment.paid}
            >
              <Icon name="dollar sign" />
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="tiny">
        <Modal.Header>Registrar Pago Parcial</Modal.Header>
        <Modal.Content>
          <Input
            label="Monto del pago parcial"
            type="number"
            value={partialPaymentAmount}
            onChange={(e) => setPartialPaymentAmount(e.target.value)}
          />
          <p>Monto restante por abonar: {formatCurrency(remainingAmount)}</p>
          {loanPayment.partialPayments &&
            loanPayment.partialPayments.length > 0 && (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Fecha</Table.HeaderCell>
                    <Table.HeaderCell>Monto</Table.HeaderCell>
                    <Table.HeaderCell>Acciones</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loanPayment.partialPayments.map((partialPayment) => (
                    <Table.Row key={partialPayment._id}>
                      <Table.Cell>
                        {format(new Date(partialPayment.date), "dd/MM/yyyy")}
                      </Table.Cell>
                      <Table.Cell>
                        {formatCurrency(partialPayment.amount)}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          icon
                          color="blue"
                          onClick={() => openEditModal(partialPayment)}
                        >
                          <Icon name="edit" />
                        </Button>
                        <Button
                          icon
                          color="red"
                          onClick={() =>
                            deletePartialPayment(partialPayment._id)
                          }
                        >
                          <Icon name="trash" />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="teal" onClick={registerPartialPayment}>
            Registrar Pago Parcial
          </Button>
          <Button color="red" onClick={() => setModalOpen(false)}>
            Cancelar
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        size="tiny"
      >
        <Modal.Header>Editar Pago Parcial</Modal.Header>
        <Modal.Content>
          <Input
            label="Monto del pago parcial"
            type="number"
            value={editedPartialPaymentAmount}
            onChange={(e) => setEditedPartialPaymentAmount(e.target.value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="teal" onClick={saveEditedPartialPayment}>
            Guardar Cambios
          </Button>
          <Button color="red" onClick={() => setEditModalOpen(false)}>
            Cancelar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
