import { Routes, Route, Navigate, Link } from "react-router-dom";
import { AdminLayout } from "../layouts";
import { EmptyList } from "../components/Shared";

import {
  Auth,
  Customer,
  Dashboard,
  Users,
  GroupLoans,
  LoanRequest,
  Role,
  LoanPayment,
  LoanDetails,
  Menu,
} from "../pages/admin";
import { CustomerView } from "../components/Admin";
import { useAuth } from "../hooks";

export function AdminRouter() {
  const { user } = useAuth();

  // Función para cargar el layout de admin
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  // Redirección para rutas no autorizadas
  const redirectToUnauthorized = () => <Navigate to="/admin/unauthorized" />;

  let validPath = true;

  return (
    <Routes>
      {!user ? (
        <Route path="/admin/*" element={<Auth />} />
      ) : (
        <>
          <Route path="/admin" element={loadLayout(AdminLayout, Dashboard)} />
          <Route
            path="/admin/dashboard"
            element={loadLayout(AdminLayout, Dashboard)}
          />
          <Route path="/admin/users" element={loadLayout(AdminLayout, Users)} />
          <Route
            path="/admin/customers"
            element={loadLayout(AdminLayout, Customer)}
          />
          <Route path="/admin/roles" element={loadLayout(AdminLayout, Role)} />
          <Route path="/admin/menus" element={loadLayout(AdminLayout, Menu)} />
          <Route
            path="/admin/loanrequests"
            element={loadLayout(AdminLayout, LoanRequest)}
          />

          <Route
            path="/admin/grouploans"
            element={loadLayout(AdminLayout, GroupLoans)}
          />
          <Route
            path="/admin/grouploan/:id"
            element={loadLayout(AdminLayout, LoanDetails)}
          />
          <Route path="/admin/unauthorized" element={<UnauthorizedPage />} />
          {/* Agregar más rutas públicas o de error aquí */}
          <Route
            path="*"
            element={
              validPath ? (
                redirectToUnauthorized()
              ) : (
                <Navigate to="/admin/unauthorized" replace />
              )
            }
          />
        </>
      )}
    </Routes>
  );
}

function UnauthorizedPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <EmptyList />
      <h1 className="text-3xl font-bold mb-2">Acceso no autorizado</h1>
      <p className="text-gray-600 mb-4">
        No tienes permiso para acceder a esta página.
      </p>
      <Link
        to="/admin/dashboard"
        className="px-4 py-2 bg-blue-500 text-white hover:text-white rounded transition-colors duration-300 hover:bg-blue-600"
      >
        Volver al Dashboard
      </Link>
    </div>
  );
}
