import { useState, useEffect } from "react";
import { Form, Input, Dropdown } from "semantic-ui-react";
import { useFormik } from "formik";
import { Role, menu } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { toast } from "react-toastify";
import { initialValues, validationSchema } from "./MenusForm.form";

const RoleController = new Role();
const MenuController = new menu();

export function MenusForm(props) {
  const [roleOptions, setRoleOptions] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true); // Estado para controlar la carga de roles
  const { close, onReload, menu } = props;
  const { accessToken } = useAuth();
  const formik = useFormik({
    initialValues: initialValues(menu),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!menu) {
          await MenuController.createMenu(accessToken, formValue);
          toast.success("Menu creado correctamente.");
        } else {
          await MenuController.updateMenu(accessToken, menu._id, formValue);
          toast.success("Menu actualizado correctamente.");
        }
        onReload();
        close();
      } catch (error) {
        toast.error(error.msg);
      }
    },
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const roles = await RoleController.getRoles(accessToken);
        setRoleOptions(
          roles.map((role) => ({
            key: role._id,
            text: role.name,
            value: role._id,
          }))
        );
        setLoadingRoles(false); // Marcamos que la carga de roles ha terminado
      } catch (error) {
        toast.error(error.msg);
      }
    };

    fetchRoles();
  }, [accessToken]);

  // Renderizar el formulario solo cuando las opciones de roles estén listas
  if (loadingRoles) {
    return <p>Cargando opciones de roles...</p>;
  }

  return (
    <Form className="" onSubmit={formik.handleSubmit}>
      <Form.Group widths={"equal"}>
        <Form.Dropdown
          label="Roles"
          name="roles"
          placeholder="Selecione un rol"
          options={roleOptions}
          value={formik.values.roles}
          onChange={(e, { value }) => formik.setFieldValue("roles", value)}
          onBlur={formik.handleBlur}
          error={formik.errors.roles}
          selection
          multiple
        />
        <Form.Input
          name="title"
          label="Titulo de ruta"
          placeholder="Titulo"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />
      </Form.Group>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>/admin/</span>
        <Input
          name="path"
          placeholder="URL"
          onChange={(e) =>
            formik.setFieldValue(
              "path",
              `/admin/${e.target.value.trim()}` // Ajustamos el valor
            )
          }
          value={formik.values.path.replace("/admin/", "")} // Mostramos solo la parte que sigue a "/admin/"
          error={formik.errors.path}
          style={{ flex: 1 }}
        />
      </div>
      <Form.Group widths={"equal"}>
        <Form.Input
          name="icon"
          label="Icono de la ruta"
          placeholder="Icono de la ruta"
          onChange={formik.handleChange}
          value={formik.values.icon}
          error={formik.errors.icon}
        />
      </Form.Group>
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {menu ? "Actualizar menu" : "Crear menu"}
      </Form.Button>
    </Form>
  );
}
