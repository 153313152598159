import "./HomeService.scss";

export function HomeService() {
  return (
    <>
      <section className=" font-noto mt-48 md:mt-40 pb-40 relative bg-white">
        {/* SVG white */}

        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="Company Image"
                  className="w-full align-middle rounded-2xl -mb-44"
                  src="https://picsum.photos/200"
                />
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto animate-fade-down animate-once animate-duration-[1800ms] animate-delay-1000 animate-fill-forwards">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa-solid fa-coins text-xl"></i>{" "}
                        {/* Modifica el ícono */}
                      </div>
                      <h6 className="text-2xl mb-1 font-semibold">
                        Tu éxito financiero es lo que más nos importa
                      </h6>
                      <p className="mb-4 text-blueGray-500 text-xl ">
                        En Konfiamos, tratamos tus necesidades financieras con
                        la atención que te mereces. Sabemos que cada cliente es
                        único y en nuestra empresa, tu bienestar financiero es
                        nuestra prioridad número uno.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto animate-fade-down animate-once animate-duration-[1800ms] animate-delay-1000 animate-fill-forwards">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa-solid fa-pen-nib text-xl"></i>{" "}
                        {/* Modifica el ícono */}
                      </div>
                      <h6 className="text-2xl mb-1 font-semibold">
                        Soluciones a tu medida
                      </h6>
                      <p className="mb-4 text-blueGray-500 text-xl ">
                        En Konfiamos, entendemos que tus necesidades financieras
                        son únicas. Ofrecemos soluciones a tu medida para que
                        puedas alcanzar tus metas financieras con confianza. Tu
                        éxito es nuestra prioridad.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto animate-fade-down animate-once animate-duration-[1800ms] animate-delay-1000 animate-fill-forwards">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa-solid fa-paperclip text-xl"></i>{" "}
                        {/* Modifica el ícono */}
                      </div>
                      <h6 className="text-2xl mb-1 font-semibold">
                        Soluciones precisas
                      </h6>
                      <p className="mb-4 text-blueGray-500 text-xl">
                        En Konfiamos, puedes confiar en nuestros expertos para
                        obtener soluciones financieras precisas y el mejor plan
                        para tus necesidades. Nuestra prioridad es
                        proporcionarte respuestas precisas para tu éxito
                        financiero.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto animate-fade-down animate-once animate-duration-[1800ms] animate-delay-1000 animate-fill-forwards">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa-solid fa-file-invoice text-xl"></i>{" "}
                        {/* Modifica el ícono */}
                      </div>
                      <h6 className="text-2xl mb-1 font-semibold">
                        Resultados Impactantes
                      </h6>
                      <p className="mb-4 text-blueGray-500 text-xl">
                        En Konfiamos, estamos comprometidos con la innovación y
                        la búsqueda de soluciones financieras que tengan un
                        impacto real en tu vida. Nuestro enfoque en la atención
                        al cliente, la educación y la investigación nos permite
                        ofrecer resultados que te ayudarán a alcanzar tus metas
                        financieras.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
