import { useState } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { BasicModal } from "../../../Shared";
import { MenusForm } from "../MenusForm";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { menu } from "../../../../api";
import { useAuth } from "../../../../hooks";

const MenuController = new menu();

export function MenuItem(props) {
  const { menu, onReload } = props;
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const isAdminMenu = menu.path === "/admin/menus"; // Verifica si es la ruta /admin/menus

  const openDesactivateActivateConfim = () => {
    setIsDelete(false);
    const action = menu.active ? "Desactivar" : "activar";
    Swal.fire({
      title: `Confirmar ${action} Menu`,
      text: `¿Estás seguro de que deseas ${action} al menu ${menu.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onActivateDesactivate();
      }
    });
  };

  const onActivateDesactivate = async () => {
    try {
      await MenuController.updateMenu(accessToken, menu._id, {
        active: !menu.active,
      });
      onReload();
      const action = menu.active ? "Desactivado" : "Activado";
      toast.success(`Menu ${action} correctamente`);
    } catch (error) {
      toast.error(error.msg);
    }
  };

  const onDelete = () => {
    Swal.fire({
      title: "Eliminar menu",
      text: `¿Estás seguro de que deseas eliminar el menu ${menu.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onDeleteConfirm();
      }
    });
  };

  const onDeleteConfirm = async () => {
    try {
      await MenuController.deleteMenu(accessToken, menu._id);
      toast.success("Menu eliminado correctamente");
      onReload();

      const result = await Swal.fire({
        title: "Menu eliminado",
        text: "¿Deseas recuperar el menu eliminado?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, recuperar",
        cancelButtonText: "No",
      });

      if (result.isConfirmed) {
        try {
          await MenuController.restoreMenu(accessToken, menu._id);
          toast.success("Menu recuperado correctamente");
          onReload();
        } catch (error) {
          toast.error(error.msg);
        }
      }
    } catch (error) {
      toast.error(error.msg);
    }
  };

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${menu.title}`);
    onOpenCloseModal();
  };

  return (
    <>
      <tr className="border-b bg-cyan-800 border-white">
        <td
          scope="row"
          className="justify-center flex p-9 px-6 py-4 text-center"
        >
          <div className="flex flex-col space-y-1">
            {menu.roles.map((role) => (
              <span
                key={role._id}
                className="px-6 py-1 font-medium text-white whitespace-nowrap"
              >
                {role.name}
              </span>
            ))}
          </div>
        </td>
        <td className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {menu.title || "No hay datos"}
        </td>
        <td className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {menu.path || "No hay datos"}
        </td>

        <td className="border border-slate-50 px-6 py-4 font-medium text-white whitespace-nowrap">
          {menu.icon || "No hay datos"}
        </td>
        <td className="border border-slate-50 py-3 px-6 text-center">
          <Button icon primary onClick={openUpdateUser}>
            <Icon name="pencil" />
          </Button>
          <Button
            icon
            color={menu.active ? "orange" : "teal"}
            disabled={isAdminMenu} // Deshabilita el botón si es /admin/menus
            onClick={openDesactivateActivateConfim}
          >
            <Icon name={menu.active ? "ban" : "check"} />
          </Button>
          <Button
            icon
            color="red"
            disabled={isAdminMenu} // Deshabilita el botón si es /admin/menus
            onClick={onDelete}
          >
            <Icon name="trash" />
          </Button>
        </td>
      </tr>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <MenusForm close={onOpenCloseModal} onReload={onReload} menu={menu} />
      </BasicModal>
    </>
  );
}
