import * as Yup from "yup";

export function initialValues(menu) {
  const roles = menu?.roles || []; // Usamos los roles del menú si existe

  return {
    roles: roles.map((role) => role._id), // Asumiendo que role._id es el valor deseado
    title: menu?.title || "",
    path: menu?.path || "",
    icon: menu?.icon || "",
  };
}

export function validationSchema() {
  return Yup.object({
    roles: Yup.array(),
    title: Yup.string(),
    path: Yup.string(),
    icon: Yup.string(),
  });
}
