import React, { useEffect, useState } from "react";
import { LoanRequest } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import "./DashboardPie.scss";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
);

const LoanRequestController = new LoanRequest();

export function DashboardDoughnut() {
  const [loanStatus, setLoanStatus] = useState(null);
  const { accessToken } = useAuth();

  useEffect(() => {
    fetchLoanStatus();
  }, []);

  const fetchLoanStatus = async () => {
    try {
      const response = await LoanRequestController.getCountStatus(accessToken);
      const { loanStatus } = response;
      setLoanStatus(loanStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const getPieColors = () => {
    const colors = {
      "Al corriente": "#0075C2",
      Atrazado: "#8E0000",
      "No iniciado": "#F2C500",
      Pagado: "#038301", // Color verde para préstamos pagados
      "En revisión": "#c2c2c2", // Color gris para préstamos cuando se revierte
    };
    return Object.keys(loanStatus || {}).map((status) => colors[status]);
  };

  const pieChartData = {
    labels: Object.keys(loanStatus || {}),
    datasets: [
      {
        data: Object.values(loanStatus || {}),
        backgroundColor: getPieColors(),
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: true,
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.parsed && context.parsed;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-screen-xl">
        <div className="text-lg font-semibold mb-4 text-center">
          Estadística de prestamos
        </div>
        <div>
          <Doughnut
            data={pieChartData}
            options={chartOptions}
            width={250}
            height={250}
          />
        </div>
      </div>
    </div>
  );
}
