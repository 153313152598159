import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { formatCurrency } from "../../../../utils";
import { addDays, format } from "date-fns";

export function LoanRequestPdf(props) {
  const { loanRequest } = props;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeader />
        <ProductDetails loanRequest={loanRequest} />
        <CustomerTable loanRequest={loanRequest} />
        <PaymentCalendar loanRequest={loanRequest} />
        <Warnings />
        <ConsultationOfTransactions />
      </Page>
    </Document>
  );
}

const PageHeader = () => (
  <View style={styles.header}>
    <Image
      style={styles.logo}
      src={require("../../../../assets/jpg/Logo Konfiamos.jpg")}
      alt="Logo"
    />
  </View>
);

const ProductDetails = ({ loanRequest }) => (
  <View style={styles.productDetails}>
    <View style={styles.centered}>
      <Text style={styles.title}>CORRIDA</Text>
    </View>
    <View style={styles.infoSection}>
      <Text style={styles.boldText}>
        Nombre Comercial del Producto: Crédito Personal
      </Text>
    </View>
    <View style={styles.infoSection}>
      <Text style={styles.boldText}>
        Codigo del prestamo: {loanRequest?.code}
      </Text>
    </View>
    <View style={styles.infoSection}>
      <Text style={styles.boldText}>Tipo de Crédito: Crédito simple</Text>
    </View>
  </View>
);

const CustomerTable = ({ loanRequest }) => (
  <View style={styles.customerDetails}>
    <Text style={styles.sectionTitle}>Clientes:</Text>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Nombre</Text>
        </View>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Telefono celular</Text>
        </View>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Monto del crédito</Text>
        </View>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Pago 15 días</Text>
        </View>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Número de pagos</Text>
        </View>
        <View style={styles.tableCellHeader}>
          <Text style={styles.label}>Monto total a pagar</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Text>
            {loanRequest.customer.firstname} {loanRequest.customer.lastname}
          </Text>
        </View>
        <View style={styles.tableCell}>
          <Text>{loanRequest.customer.cellnumber}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text>{formatCurrency(loanRequest.amountRequested)}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text>{formatCurrency(loanRequest.payments[0].paymentAmount)}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text>{loanRequest.payments.length}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text>{formatCurrency(loanRequest.totalAmount)}</Text>
        </View>
      </View>
    </View>
  </View>
);

const PaymentCalendar = ({ loanRequest }) => {
  return (
    <View style={styles.paymentDetails}>
      <Text style={styles.sectionTitle}>Calendario de Pagos:</Text>
      <View style={styles.paymentTable}>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text style={styles.label}>Monto</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.label}>Fecha de pago</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.label}>Pagado</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.label}>Estado</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.label}>Fecha de vencimiento</Text>
          </View>
        </View>
        {loanRequest.payments.map((payments, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text>{formatCurrency(payments.paymentAmount)}</Text>{" "}
            </View>
            <View style={styles.tableCell}>
              <Text>
                {format(
                  addDays(new Date(payments.paymentDate), 1),
                  "dd/MM/yyyy "
                )}
              </Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{payments?.paid ? "Sí" : "No"}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{payments?.status}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>
                {format(addDays(new Date(payments.dueDate), 1), "dd/MM/yyyy ")}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const ConsultationOfTransactions = () => {
  return (
    <View style={styles.consultationContainer}>
      <Text style={styles.consultationTitle}>CONSULTA DE MOVIMIENTOS:</Text>
      <Text style={styles.consultationText}>
        Via WhatsApp: Ejecutivo de Cuenta
      </Text>
    </View>
  );
};

const Warnings = () => (
  <View style={styles.warningContainer}>
    <Text style={styles.warningTitle}>ADVERTENCIAS</Text>
    <Text style={styles.warningText}>
      Incumplir tus obligaciones te puede generar comisiones e intereses
      moratorios. Contratar créditos que excedan tu capacidad de pago afecta tu
      historial crediticio.
    </Text>
  </View>
);

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 10,
    paddingLeft: 19.4,
    paddingBottom: 30,
    paddingRight: 19.4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 90,
    marginRight: 10,
  },
  productDetails: {
    marginBottom: 20,
  },
  centered: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
  },
  infoSection: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#333333",
  },
  customerDetails: {
    marginBottom: 20,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "#f2f2f2", // Color de fondo del encabezado
  },
  tableCell: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  paymentDetails: {
    marginBottom: 20,
  },
  paymentTable: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableCellPayment: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  warningContainer: {
    marginBottom: 10, // Añade un margen inferior para separar el bloque de advertencias del contenido principal.
  },
  warningTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 5, // Añade un margen inferior para separar el título de la advertencia.
  },
  warningText: {
    fontSize: 10, // Puedes ajustar el tamaño del texto según tus necesidades.
  },
  consultationContainer: {
    marginBottom: 10, // Add a bottom margin to separate the consultation block from the main content.
  },
  consultationTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 5, // Add a bottom margin to separate the consultation title.
  },
  consultationText: {
    fontSize: 10, // You can adjust the text size according to your needs.
  },
});
