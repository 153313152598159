import * as Yup from "yup";

export function initialValues(groupLoan) {
  const loanRequestIds = groupLoan?.loanRequests || []; // Usamos los prestamos del grupo si existe

  return {
    loanRequestIds: loanRequestIds.map((loanRequest) => loanRequest._id), // Asumiendo que loanRequest._id es el valor deseado
    name: groupLoan?.name || "",
  };
}

export function validationSchema() {
  return Yup.object({
    loanRequestIds: Yup.array(),
    name: Yup.string(),
  });
}
