import { Banner, HomeService } from "../../../components/web";

export function Home() {
  return (
    <div>
      <Banner />
      <HomeService />
    </div>
  );
}
