import { Container } from "semantic-ui-react";
import { TopBar, Footer } from "../../components/web";
import "./ClientLayout.scss";

export function ClientLayout(props) {
  const { children } = props;
  return (
    <div>
      <div className="client-layout__header">
        <TopBar />
      </div>

      {children}

      <div className="client-layout__footer">
        <Container>
          <p className="credit"> &copy; Konfiamos {new Date().getFullYear()}</p>
        </Container>
      </div>
    </div>
  );
}
