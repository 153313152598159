import { useState } from "react";
import { Loader, Button } from "semantic-ui-react";
import { size, map } from "lodash";
import { LoanRequest } from "../../../../api";
import { LoanPaymentsItem } from "../LoanPaymentsItem";
import { EmptyList } from "../../../Shared";
import ReactPaginate from "react-paginate";

export function ListLoanPayments(props) {
  const { close, reload, onReload, loanRequest } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Número de pagos por página

  if (loanRequest.payments === null) {
    // Muestra el loader mientras los datos se están cargando
    return <Loader active inline="centered" />;
  }
  if (size(loanRequest.payments) === 0) {
    return <EmptyList title="No hay ningún pago asociado al préstamo." />;
  }

  // Obtener la lista de clientes para la página actual
  const offset = currentPage * pageSize;
  const currentLoanPayments = loanRequest.payments.slice(
    offset,
    offset + pageSize
  );

  // Calcular el número total de páginas
  const pageCount = Math.ceil(loanRequest.payments.length / pageSize);

  // Cambiar de página
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Cambiar la cantidad de clientes por página
  const handlePageSizeChange = (event) => {
    const size = parseInt(event.target.value);
    setPageSize(size);
    setCurrentPage(0);
  };

  return (
    <>
      <div className="pageSize-container">
        <label htmlFor="pageSize">Mostrar:</label>
        <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
      </div>

      <div className="relative overflow-x-auto shadow-2xl sm:rounded-lg">
        <table className="w-full border border-slate-50 text-base text-center text-white">
          <thead className="border-b text-base uppercase bg-[#687584] text-white">
            <tr className="border border-slate-50">
              <th className="py-3 px-6 text-center sm:w-1/4">Fecha de pago</th>

              <th className="py-3 px-6 text-center sm:w-1/4">
                Fecha limite de pago
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">Monto a pagar</th>
              <th className="py-3 px-6 text-center sm:w-1/4">
                Estatus de pago
              </th>
              {/* <th className="py-3 px-6 text-center sm:w-1/4">
                  Estatus de pago parcial
                </th> */}
              <th className="py-3 px-6 text-center sm:w-1/4">
                Monto pago parcial
              </th>
              <th className="py-3 px-6 text-center sm:w-1/4">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {map(currentLoanPayments, (loanPayment) => (
              <LoanPaymentsItem
                key={loanPayment._id}
                loanPayment={loanPayment}
                onReload={onReload}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        {/* Botón de Cerrar en el lado derecho */}
        <Button
          content="Cerrar"
          icon="close"
          color="red"
          labelPosition="left"
          onClick={close}
        />
      </div>
    </>
  );
}
